import { useDispatch, useSelector } from 'react-redux'
import { memo, useEffect, useState } from 'react'

import {
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  Dialog,
  FormGroup,
  Checkbox,
  TextField,
} from '@mui/material'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { getPolesUniqueAiObjects, runUtilityInfrastructureAnalysis, setUniqueAiObjectsDialogState } from '../../actions'

const PolesUniqueAiObjectsDialog = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)
  const dispatch = useDispatch()
  const { list, isLoading, isOpen } = useSelector((state) => state.utilityInfrastructure.uniqueAiObjectsDialogState)
  const contentId = useSelector(getContentId)
  const polesList = list.filter((p) => p.Name.toLowerCase().includes(debouncedSearchText.toLowerCase()))

  useEffect(() => {
    if (isOpen) {
      dispatch(getPolesUniqueAiObjects.request(contentId))
    }
  }, [dispatch, isOpen, contentId])

  const _handleChange = (e) => {
    const { value } = e.target
    const isChecked = e.target.checked
    const numberValue = parseInt(value, 10)
    if (isChecked) {
      setSelectedIds([...selectedIds, numberValue])
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== numberValue))
    }
  }

  const _handleClose = () => {
    setSelectedIds([])
    dispatch(setUniqueAiObjectsDialogState({ isOpen: false }))
  }

  const _handleSubmit = () => {
    dispatch(runUtilityInfrastructureAnalysis.request({ uniqueAiObjectsIds: selectedIds, contentId }))
    _handleClose()
  }

  const isAllSelected = polesList.every((p) => selectedIds.includes(p.Id))

  const _selectAll = () => {
    if (isAllSelected) {
      setSelectedIds([])
    } else {
      const selected = polesList.map((p) => p.Id)
      setSelectedIds(selected)
    }
  }

  return (
    <Dialog open={isOpen} onClose={_handleClose}>
      <DialogTitle sx={{ pb: 0 }}>Pole(s) for the analysis</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgressBar />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              value={searchText}
              fullWidth
              placeholder='Search...'
              size='small'
              variant='outlined'
              onChange={(e) => setSearchText(e.target.value)}
            />
            {polesList?.length > 0 && (
              <FormGroup sx={{ pt: 1 }}>
                <FormControlLabel
                  control={<Checkbox checked={isAllSelected} onChange={_selectAll} color='default' />}
                />
                {polesList.map((uniqueObject) => {
                  const isChecked = selectedIds.includes(uniqueObject.Id)

                  const props = {
                    checked: isChecked,
                    onChange: _handleChange,
                    value: uniqueObject.Id,
                  }

                  return (
                    <FormControlLabel
                      control={<Checkbox {...props} color='default' />}
                      label={uniqueObject.Name}
                      key={uniqueObject.Id}
                    />
                  )
                })}
              </FormGroup>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={_handleSubmit} disabled={selectedIds.length === 0}>
          Run
        </Button>
        <Button variant='outlined' onClick={_handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(PolesUniqueAiObjectsDialog)
