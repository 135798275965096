import { NotificationEventType } from '@tabeeb/enums'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { put, all, takeLatest } from 'redux-saga/effects'

function* onNotifyContent(action) {
  const [notification] = action.payload

  if (notification.eventType === NotificationEventType.WorkflowFailed) {
    yield put(onAddErrorNotification({ message: notification.message }))
  }
}

function* workflowSaga() {
  yield all([takeLatest(signalrEvents[signalrConstants.tabeebHubName].onNotifyContent, onNotifyContent)])
}

export default workflowSaga
